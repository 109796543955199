import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { UserPreferenceEnum } from '@shared/enums/common-data/user-preference.enum';
import { BuildVersionCacheModel } from '@shared/models/shared/build-version-cache.model';
import { DefaultQueueCacheModel } from '@shared/models/shared/detault-queue-cache.model';
import { TopOfStackCacheModel } from '@shared/models/shared/top-of-stack-cache.model';
import { UserPreferenceModel } from '@shared/models/shared/user-preference.model';
import { UserPreferenceComponentNameType } from '@shared/types/general/user-preference.types';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { UserPreferenceActions } from '../actions/user-preference.actions';
import { UserPreferenceSelectors } from '../selectors/user-preference.selector';

export interface ReleaseNotes {
  buildVersion: string;
}

@Injectable()
export class UserPreferenceHandlerService {

  // ACTIONS ************************************************************************************************************************
  getTopOfStackCacheSuccess$ = this.actions$.pipe(
    ofType(UserPreferenceActions.getTopOfStackCacheSuccess),
    map((action) => action.preferences)
  );
  getTopOfStackCacheFail$ = this.actions$.pipe(
    ofType(UserPreferenceActions.getTopOfStackCacheFail),
    map((action) => action.errors)
  );

  // SELECTORS ***********************************************************************************************************************
  correctionsDefaultQueuePreferencesSelector$ = this.store$.pipe(select(UserPreferenceSelectors.correctionsDefaultQueuePreferences));
  correctionsBuildVersionPreferencesSelector$ = this.store$.pipe(select(UserPreferenceSelectors.correctionsBuildVersionPreferences));
  correctionsBuildVersionPreferencesSuccess$ = this.actions$.pipe(
    ofType(UserPreferenceActions.getBuildVersionPreferenceSuccess),
    map((action) => action.preferences)
  );
  getTopOfStackCacheSelector$ = this.store$.pipe(select(UserPreferenceSelectors.getTopOfStackCache));

  constructor(public store$: Store, public actions$: Actions) {}

  getDefaultQueuePreference(componentName: UserPreferenceComponentNameType): void {
    this.store$.dispatch(UserPreferenceActions.getDefaultQueuePreference({ componentName }));
  }

  updateDefaultQueuePreference(componentName: UserPreferenceComponentNameType, preferences: DefaultQueueCacheModel): void {
    this.store$.dispatch(UserPreferenceActions.updateDefaultQueuePreference({ componentName, preferences }));
  }

  getBuildVersionPreference(componentName: UserPreferenceComponentNameType): void {
    this.store$.dispatch(UserPreferenceActions.getBuildVersionPreference({ componentName }));
  }

  updateBuildVersionPreference(componentName: UserPreferenceComponentNameType, preferences: BuildVersionCacheModel): void {
    this.store$.dispatch(UserPreferenceActions.updateBuildVersionPreference({ componentName, preferences }));
  }

  getTopOfStackCache(): void {
    this.store$.dispatch(UserPreferenceActions.getTopOfStackCache({componentName: UserPreferenceEnum.topOfStackCache}));
  }

  // NOTE: To call this method is important first to get the top of stack cache data from the api in order to update it avoiding override it
  updateTopOfStackCache(topOfStackCache: TopOfStackCacheModel): void {
    this.getTopOfStackCacheSelector$.pipe(take(1)).subscribe(
      topOfStackCacheStore => {
        this.store$.dispatch(UserPreferenceActions.updateTopOfStackCache({topOfStackCache: { ...topOfStackCacheStore, ...topOfStackCache }}));
      }
    );
  }

  doNewReleaseNotesExist(currentBuildVersion: string): Observable<boolean> {
    return this.correctionsBuildVersionPreferencesSelector$.pipe(
      map((preference: UserPreferenceModel | undefined) => preference?.buildVersion !== currentBuildVersion),
    );
  }

  dismissNewReleaseNotes(componentName: UserPreferenceComponentNameType, buildVersion: string): void {
    this.updateBuildVersionPreference(componentName, { buildVersion });
  }
}

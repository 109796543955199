import { Directive, ElementRef, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { SubCollection } from '@shared/utils/rx/sub-collection';

@Directive({
  selector: '[appInputTextFormat][formControlName],[appInputTextFormat][formControl],[appInputTextFormat][ngModel]'
})
export class InputTextFormatDirective implements OnInit, OnDestroy {

  @Input() inputTextFormatApplyTrim = true;

  subs$ = new SubCollection();

  constructor(private ngControl: NgControl, private el: ElementRef) { }

  private setTransformDataValue(value: string): void {
    if (typeof value !== 'string') return;
    this.ngControl.control?.setValue(value.toUpperCase(), { emitEvent: false });
  }

  ngOnInit(): void {
    this.setTransformDataValue(this.ngControl.control?.value);
    this.onInput();
  }

  ngOnDestroy(): void {
    this.subs$.unsubscribe();
  }

  onInput(): void {
    if (!this.ngControl.control) return;

    this.subs$.add = this.ngControl.control.valueChanges.subscribe(_ => {
      const cursorPosition: number = this.el.nativeElement.selectionStart;
      this.setTransformDataValue(this.ngControl.value);
      this.el.nativeElement.selectionEnd = cursorPosition;
    });
  }

  @HostListener('blur')
  onBlur(): void {
    this.applyTextFormat();
  }

  @HostListener('keydown', ['$event'])
  onKeyPress(event: KeyboardEvent): void {
    if (event.code === 'Enter') this.applyTextFormat();
  }

  private applyTextFormat(): void {
    const value = this.ngControl.value;
    if (typeof value !== 'string' || !this.inputTextFormatApplyTrim) return;
    this.ngControl.control?.setValue(value.trim(), { emitEvent: false });
  }

}

import { createFeatureSelector, createReducer, on } from '@ngrx/store';
import { UserPreferenceEnum } from '@shared/enums/common-data/user-preference.enum';
import { BuildVersionCacheModel } from '@shared/models/shared/build-version-cache.model';
import { DefaultQueueCacheModel } from '@shared/models/shared/detault-queue-cache.model';
import { TopOfStackCacheModel } from '@shared/models/shared/top-of-stack-cache.model';
import { UserPreferenceConfig } from '@shared/models/shared/user-preference.model';
import { UserPreferenceActions } from '../actions/user-preference.actions';

const userPreferenceFeatureKey = 'userPreference';
const userPreferenceFeatureSelector = createFeatureSelector<UserPreferenceState>(userPreferenceFeatureKey);

export interface UserPreferenceState {
  [UserPreferenceEnum.defaultQueue]?: DefaultQueueCacheModel;
  [UserPreferenceEnum.buildVersion]?: BuildVersionCacheModel;
  [UserPreferenceEnum.topOfStackCache]?: TopOfStackCacheModel;
}

const userPreferenceInitState: UserPreferenceState = {
  [UserPreferenceEnum.buildVersion]: {buildVersion: UserPreferenceConfig.userPreferenceInitialState.buildVersion},
  [UserPreferenceEnum.defaultQueue]: undefined,
  [UserPreferenceEnum.topOfStackCache]: undefined
};

const reducer = createReducer(
  userPreferenceInitState,
  on(UserPreferenceActions.getDefaultQueuePreference, (state, { componentName }) => {
    return {
      ...state,
      [componentName]: userPreferenceInitState[componentName],
    };
  }),
  on(
    UserPreferenceActions.getDefaultQueuePreferenceSuccess,
    UserPreferenceActions.updateDefaultQueuePreferenceSuccess,
    (state, { componentName, preferences }) => {
      return {
        ...state,
        [componentName]: preferences,
      };
    }
  ),
  on(UserPreferenceActions.getBuildVersionPreference, (state, { componentName }) => {
    return {
      ...state,
      [componentName]: userPreferenceInitState[componentName],
    };
  }),
  on(
    UserPreferenceActions.getBuildVersionPreferenceSuccess,
    UserPreferenceActions.updateBuildVersionPreferenceSuccess,
    (state, { componentName, preferences }) => {
      return {
        ...state,
        [componentName]: preferences,
      };
    }
  ),
  on(UserPreferenceActions.getTopOfStackCache, (state, { componentName }) => {
    return {
      ...state,
      [componentName]: userPreferenceInitState[componentName],
    };
  }),
  on(
    UserPreferenceActions.getTopOfStackCacheSuccess,
    UserPreferenceActions.updateTopOfStackCacheSuccess,
    (state, { componentName, preferences }) => {
      return {
        ...state,
        [componentName]: preferences,
      };
    }
  ),
);

export const UserPreferenceReducer = {
  reducer,
  userPreferenceFeatureKey,
  userPreferenceFeatureSelector,
  userPreferenceInitState,
};

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { UserPreferenceEnum } from '@shared/enums/common-data/user-preference.enum';
import { BuildVersionCacheModel } from '@shared/models/shared/build-version-cache.model';
import { DefaultQueueCacheModel } from '@shared/models/shared/detault-queue-cache.model';
import { ErrorModel } from '@shared/models/shared/error.model';
import { TopOfStackCacheModel } from '@shared/models/shared/top-of-stack-cache.model';
import { UserPreferenceDataApiService } from '@shared/services/apis/user-preferences/user-preferences-data-api.service';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { UserPreferenceActions } from '../actions/user-preference.actions';

@Injectable()
export class UserPreferenceEffects {
  getDefaultQueuePreference$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserPreferenceActions.getDefaultQueuePreference),
      exhaustMap(({ componentName }) => {
        return this.userPreferenceDataApiService.getUserPreference<DefaultQueueCacheModel>(componentName).pipe(
          map(preferences =>
            UserPreferenceActions.getDefaultQueuePreferenceSuccess({ componentName, preferences })
          ),
          catchError((errors: ErrorModel[]) =>
            of(UserPreferenceActions.getDefaultQueuePreferenceFail({ componentName, errors }))
          )
        );
      })
    )
  );

  updateDefaultQueuePreference$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserPreferenceActions.updateDefaultQueuePreference),
      exhaustMap(({ componentName, preferences }) => {
        return this.userPreferenceDataApiService.updateUserPreference<DefaultQueueCacheModel>(componentName, preferences).pipe(
          map(() => UserPreferenceActions.updateDefaultQueuePreferenceSuccess({ componentName, preferences })),
          catchError((errors: ErrorModel[]) =>
            of(UserPreferenceActions.updateDefaultQueuePreferenceFail({ componentName, errors }))
          )
        );
      })
    )
  );

  getBuildVersionPreference$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserPreferenceActions.getBuildVersionPreference),
      exhaustMap(({ componentName }) => {
        return this.userPreferenceDataApiService.getUserPreference<BuildVersionCacheModel>(componentName).pipe(
          map(preferences =>
            UserPreferenceActions.getBuildVersionPreferenceSuccess({ componentName, preferences })
          ),
          catchError((errors: ErrorModel[]) =>
            of(UserPreferenceActions.getBuildVersionPreferenceFail({ componentName, errors }))
          )
        );
      })
    )
  );

  updateBuildVersionPreference$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserPreferenceActions.updateBuildVersionPreference),
      exhaustMap(({ componentName, preferences }) => {
        return this.userPreferenceDataApiService.updateUserPreference<BuildVersionCacheModel>(componentName, preferences).pipe(
          map(() => UserPreferenceActions.updateBuildVersionPreferenceSuccess({ componentName, preferences })),
          catchError((errors: ErrorModel[]) =>
            of(UserPreferenceActions.updateBuildVersionPreferenceFail({ componentName, errors }))
          )
        );
      })
    )
  );

  getTopOfStackCache$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserPreferenceActions.getTopOfStackCache),
      exhaustMap(({componentName}) => {
        return this.userPreferenceDataApiService.getUserPreference<TopOfStackCacheModel>(componentName).pipe(
          map(preferences => {
            return UserPreferenceActions.getTopOfStackCacheSuccess({ componentName, preferences });
          }),
          catchError((errors: ErrorModel[]) =>
            of(UserPreferenceActions.getTopOfStackCacheFail({ componentName, errors }))
          )
        );
      })
    )
  );

  updateTopOfStackCache$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserPreferenceActions.updateTopOfStackCache),
      exhaustMap(({topOfStackCache}) => {
        const componentName = UserPreferenceEnum.topOfStackCache;
        return this.userPreferenceDataApiService.updateUserPreference<TopOfStackCacheModel>(componentName, topOfStackCache).pipe(
          map(() => {
            return UserPreferenceActions.updateTopOfStackCacheSuccess({componentName, preferences: topOfStackCache});
          }),
          catchError((errors: ErrorModel[]) =>
            of(UserPreferenceActions.updateTopOfStackCacheFail({ componentName, errors }))
          )
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private userPreferenceDataApiService: UserPreferenceDataApiService,
  ) {}
}

import { createAction, props } from '@ngrx/store';
import { BuildVersionCacheModel } from '@shared/models/shared/build-version-cache.model';
import { DefaultQueueCacheModel } from '@shared/models/shared/detault-queue-cache.model';
import { ErrorModel } from '@shared/models/shared/error.model';
import { TopOfStackCacheModel } from '@shared/models/shared/top-of-stack-cache.model';
import { UserPreferenceComponentNameType } from '@shared/types/general/user-preference.types';

const getDefaultQueuePreference = createAction(
  '[UserPreferenceActions] Get default queue preference data',
  props<{ componentName: UserPreferenceComponentNameType }>()
);
const getDefaultQueuePreferenceSuccess = createAction(
  '[UserPreferenceActions] Get default queue preference data Success',
  props<{ componentName: UserPreferenceComponentNameType; preferences: DefaultQueueCacheModel | undefined }>()
);
const getDefaultQueuePreferenceFail = createAction(
  '[UserPreferenceActions] Get default queue preference data Fail',
  props<{ componentName: UserPreferenceComponentNameType; errors: ErrorModel[] }>()
);

const getBuildVersionPreference = createAction(
  '[UserPreferenceActions] Get build version preference data',
  props<{ componentName: UserPreferenceComponentNameType }>()
);
const getBuildVersionPreferenceSuccess = createAction(
  '[UserPreferenceActions] Get build version preference data Success',
  props<{ componentName: UserPreferenceComponentNameType; preferences: BuildVersionCacheModel | undefined }>()
);
const getBuildVersionPreferenceFail = createAction(
  '[UserPreferenceActions] Get build version preference data Fail',
  props<{ componentName: UserPreferenceComponentNameType; errors: ErrorModel[] }>()
);

const updateDefaultQueuePreference = createAction(
  '[UserPreferenceActions] Update default queue preference data',
  props<{ componentName: UserPreferenceComponentNameType; preferences: DefaultQueueCacheModel }>()
);
const updateDefaultQueuePreferenceSuccess = createAction(
  '[UserPreferenceActions] Update default queue preference data Success',
  props<{ componentName: UserPreferenceComponentNameType; preferences: DefaultQueueCacheModel }>()
);
const updateDefaultQueuePreferenceFail = createAction(
  '[UserPreferenceActions] Update default queue preference data Fail',
  props<{ componentName: UserPreferenceComponentNameType; errors: ErrorModel[] }>()
);

const updateBuildVersionPreference = createAction(
  '[UserPreferenceActions] Update build version preference data',
  props<{ componentName: UserPreferenceComponentNameType; preferences: BuildVersionCacheModel }>()
);
const updateBuildVersionPreferenceSuccess = createAction(
  '[UserPreferenceActions] Update build version preference data Success',
  props<{ componentName: UserPreferenceComponentNameType; preferences: BuildVersionCacheModel }>()
);
const updateBuildVersionPreferenceFail = createAction(
  '[UserPreferenceActions] Update build version preference data Fail',
  props<{ componentName: UserPreferenceComponentNameType; errors: ErrorModel[] }>()
);

const getTopOfStackCache = createAction(
  '[UserPreferenceActions] Get the top of stack cache',
  props<{ componentName: UserPreferenceComponentNameType }>()
);
const getTopOfStackCacheSuccess = createAction(
  '[UserPreferenceActions] Get the top of stack cache Success',
  props<{ componentName: UserPreferenceComponentNameType, preferences?: TopOfStackCacheModel }>()
);
const getTopOfStackCacheFail = createAction(
  '[UserPreferenceActions] Get the top of stack cache Fail',
  props<{ componentName: UserPreferenceComponentNameType, errors: ErrorModel[] }>()
);

const updateTopOfStackCache = createAction(
  '[UserPreferenceActions] Update the top of stack cache',
  props<{ topOfStackCache: TopOfStackCacheModel }>()
);
const updateTopOfStackCacheSuccess = createAction(
  '[UserPreferenceActions] Update the top of stack cache Success',
  props<{ componentName: UserPreferenceComponentNameType; preferences: TopOfStackCacheModel }>()
);
const updateTopOfStackCacheFail = createAction(
  '[UserPreferenceActions] Update the top of stack cache Fail',
  props<{ componentName: UserPreferenceComponentNameType, errors: ErrorModel[] }>()
);

export const UserPreferenceActions = {
  getDefaultQueuePreference,
  getDefaultQueuePreferenceSuccess,
  getDefaultQueuePreferenceFail,
  getBuildVersionPreference,
  getBuildVersionPreferenceSuccess,
  getBuildVersionPreferenceFail,
  updateDefaultQueuePreference,
  updateDefaultQueuePreferenceSuccess,
  updateDefaultQueuePreferenceFail,
  updateBuildVersionPreference,
  updateBuildVersionPreferenceSuccess,
  updateBuildVersionPreferenceFail,
  getTopOfStackCache,
  getTopOfStackCacheSuccess,
  getTopOfStackCacheFail,
  updateTopOfStackCache,
  updateTopOfStackCacheSuccess,
  updateTopOfStackCacheFail,
};

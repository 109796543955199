import { FormGroup } from "@angular/forms";
import { CorrectionsFormEnum } from "@shared/enums/corrections/corrections-form/corrections-form.enum";
import { CorrectionsTariffFormEnum } from "@shared/enums/corrections/corrections-form/tariff/corrections-tariff-form.enum";
import { DateFormat } from "@shared/enums/common-data/date-format.enum";
import { CorrectionsTariffFormModel } from "@shared/models/corrections/corrections-form/tariff/corrections-tariff.model";
import { DateUtils } from "@shared/utils/date/date-utils.service";
import * as moment from 'moment';

export abstract class CorrectionsTariffGetForm {
  static getForm = (form: FormGroup): FormGroup => {
    return form.controls[CorrectionsFormEnum.tariff] as FormGroup;
  }

  static getFormValues = (form: FormGroup): CorrectionsTariffFormModel => {
    const code = form.controls[CorrectionsTariffFormEnum.code];
    const effectiveDate = form.controls[CorrectionsTariffFormEnum.effectiveDate];

    return {
      code,
      effectiveDate
    };
  };

  static getEffectiveDateValueToCompare = (effectiveDate: moment.Moment | string): string => {
    return effectiveDate === 'string'
      ? effectiveDate
      : DateUtils.momentToString(effectiveDate, DateFormat.formRawValueDates);
  };
}

<ng-container [ngSwitch]="htmlTagType">

  <address *ngSwitchCase="'address'" [id]="id" class="content" [ngClass]="[getChangesColor(), getEmptyClass()]" #textContent [innerHTML]="displayValue"></address>

  <time *ngSwitchCase="'date'" [id]="id" class="content" [ngClass]="[getChangesColor(), getEmptyClass()]" #textContent [innerHTML]="displayValue"></time>

  <p *ngSwitchCase="'paragraph'" [id]="id" class="content" [ngClass]="[getChangesColor(), getEmptyClass()]" #textContent [innerHTML]="displayValue"></p>

  <span *ngSwitchDefault [id]="id" class="content" [ngClass]="[getChangesColor(), getEmptyClass()]" #textContent [innerHTML]="displayValue"></span>

</ng-container>

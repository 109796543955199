import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CorrectionsFormAuditorRemarksGetForm } from '@corrections/corrections-form/shared/form/auditor-remarks/corrections-form-auditor-remarks-get-form-groups.utils';
import { CorrectionsCancelChargesGetForm } from '@corrections/corrections-form/shared/form/cancel-charges/corrections-cancel-charges-get-form-groups.utils';
import { CorrectionsTariffGetForm } from '@corrections/corrections-form/shared/form/tariff/corrections-form-tariff-get-form-groups.utils';
import { CorrectionsMessagesValue } from '@shared/data-values/corrections/messages.data';
import { RatingCancelChargesEnum, RatingCancelChargesErrorMessageEnum } from '@shared/enums/common-data/tariffCancelCharges.enum';
import { ErrorModel } from '@shared/models/shared/error.model';
import { RequestFormRolesService } from '@shared/services/application-utils/user-roles/request-form/request-form-roles.service';
import { NonRevenuesService } from '@shared/services/non-revenues/non-revenues/non-revenues.service';

@Injectable()
export class AuditorRemarksValidatorsService {

  constructor(private requestFormRolesService: RequestFormRolesService, private nonRevenuesService: NonRevenuesService) { }

  auditorCommentsRequired(form: FormGroup): ErrorModel[] {
    const errors: ErrorModel[] = [];
    const remarksForm = CorrectionsFormAuditorRemarksGetForm.getForm(form);
    const { auditorComments } = CorrectionsFormAuditorRemarksGetForm.getFormValues(remarksForm);
    if (!auditorComments.value) {
      const auditorError: ErrorModel = { message: CorrectionsMessagesValue.auditorComments.empty };
      errors.push(auditorError);
    }
    return errors;
  }

  manualRateInd(form: FormGroup): ErrorModel[] {
    //LECS-1065: this validation is not needed for non revenues, we are validating if we can remove the cancel charges section and avoid this second condition
    if(!this.requestFormRolesService.addAutoRateValidation() || this.nonRevenuesService.checkIfIsNonRevenue()) return [];
    const errors: ErrorModel[] = [];
    const remarksForm = CorrectionsFormAuditorRemarksGetForm.getForm(form);
    const { cancelChargesOpt } = CorrectionsCancelChargesGetForm.getFormValues(CorrectionsCancelChargesGetForm.getForm(form));
    const { code } = CorrectionsTariffGetForm.getFormValues(CorrectionsTariffGetForm.getForm(form));
    const { manualRateInd } = CorrectionsFormAuditorRemarksGetForm.getFormValues(remarksForm);
    if (cancelChargesOpt.value && manualRateInd.value === false) { 
      const auditorError: ErrorModel = { message: RatingCancelChargesErrorMessageEnum.cancelChargesErrorMessage };
      errors.push(auditorError);
    } else if ((code.value === RatingCancelChargesEnum.cts992) && manualRateInd.value === false) { 
      const auditorError: ErrorModel = { message: RatingCancelChargesErrorMessageEnum.tariffCts992ErrorMessage };
      errors.push(auditorError);
    };
    return errors;
  }
}

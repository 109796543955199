export enum CorrectionsCancelChargesFormEnum {
  cancelChargesOpt = 'cancelChargesOpt',
};

export enum CorrectionsCancelChargesIdsEnum {
  cancelChargesOpt = 'cancelChargesOptInd',
};

export enum CorrectionsCancelChargesTitlesEnum {
  title = 'Cancel Charges',
};

export enum CorrectionsCancelChargesLabelsEnum {
  cancelCharge = 'Do you want to cancel Charges?',
};

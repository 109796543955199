import { Injectable } from '@angular/core';
import { CorrectionsHandler } from '@app-store/corrections/handler/corrections.handler';
import { BillClassDescriptionEnum, BillClassEnum } from '@shared/enums/common-data/bill-class.enum';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NonRevenuesService {
  billClassesDescription = BillClassDescriptionEnum;

  constructor(private correctionsHandler: CorrectionsHandler) { }

  isNonRevenue(billClass: BillClassEnum | undefined): boolean {
    switch(billClass) {
      case BillClassEnum.partialSegment:
      case BillClassEnum.generalClaimsBusShpmt:
      case BillClassEnum.astrayFrtSegment:
      case BillClassEnum.coBusShpmt:
      case BillClassEnum.expedite:
        return true;

      default:
        return false;
    }
  }

  checkIfIsNonRevenue(): boolean {
    const billClassCd = this.getBillClassToCheckNonRevenue();
    return this.isNonRevenue(billClassCd);
  }

  showBillClassDescription(billClass: BillClassEnum | undefined): string | undefined {
    switch(billClass) {
      case BillClassEnum.coBusShpmt:
        return this.billClassesDescription.coBusShpmt
      case BillClassEnum.masterSegment:
        return this.billClassesDescription.masterSegment
      case BillClassEnum.expedite:
        return this.billClassesDescription.expedite
      case BillClassEnum.partialSegment:
        return this.billClassesDescription.partialSegment
      case BillClassEnum.splitSegment:
        return this.billClassesDescription.splitSegment
      case BillClassEnum.masterShpmt:
        return this.billClassesDescription.masterSegment
      case BillClassEnum.astrayFrtSegment:
        return this.billClassesDescription.astrayFrtSegment

      default:
        return undefined;
    }
  }

  showChildPros(billClass: BillClassEnum | undefined): boolean {
    switch(billClass) {
      case BillClassEnum.splitSegment:
      case BillClassEnum.masterSegment:
        return true

      default:
        return false;
    }
  }

  private getBillClassToCheckNonRevenue(): BillClassEnum | undefined {
    let billClassCd: BillClassEnum | undefined;
    this.correctionsHandler.getCorrectionsDetail$.pipe(take(1)).subscribe(
      correctionsDetail => billClassCd = correctionsDetail?.billStatus?.billClass
    );

    return billClassCd;
  }
}

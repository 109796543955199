import { AbstractControl, FormGroup } from "@angular/forms";
import { CorrectionsCancelChargesFormEnum } from "@shared/enums/corrections/corrections-form/cancel-charges/corrections-cancel-charges-form.enum";
import { CorrectionsFormEnum } from "@shared/enums/corrections/corrections-form/corrections-form.enum";
import { CorrectionsCancelChargesFormModel } from "@shared/models/corrections/corrections-form/cancel-charges/corrections-cancel-charges.model";


export abstract class CorrectionsCancelChargesGetForm {
    static getForm = (form: FormGroup): FormGroup => {
        return form.controls[CorrectionsFormEnum.cancelCharges] as FormGroup;
    }

    static getFormValues = (form: FormGroup): CorrectionsCancelChargesFormModel => {
        const cancelChargesOpt: AbstractControl = form.controls[CorrectionsCancelChargesFormEnum.cancelChargesOpt];

        return { cancelChargesOpt };
    }
}

export enum CorrectionsTariffFormEnum {
    code = 'code',
    effectiveDate = 'effectiveDate',
};

export enum CorrectionsTariffFormLabelEnum {
    code = 'Tariff Code',
    effectiveDate = 'effective date',
    warning = 'Tariff section used mainly by the AR Dept.'
};

export enum CorrectionsTariffFormIdsEnum {
    code = 'tariffCode',
    effectiveDate = 'tariffEffectiveDate'
};

export enum CorrectionsTariffFormTitlesEnum {
    title = 'Tariff'
};

import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { TextTransformFormatService } from '@shared/services/application-utils/transforms/text/text-transform-format.service';
import { HtmlTagType, TextFormatType } from '@shared/types/general/text.types';

/**
 * displayValue: is the value to display with formats applied
 * defaultValue: is the original value to display without formats applied
 * currentValue: is the current value to display without formats applied
 * 
 * NOTE: when the component have defaultValue and currentValue the method showChangesColor would work
 */
@Component({
  selector: 'app-text-description-form',
  templateUrl: './text-description-form.component.html',
  styleUrls: ['./text-description-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextDescriptionFormComponent implements OnChanges, AfterViewInit {
  @Input() id = '';
  @Input() showValueChangesColor = false;
  @Input() textFormat: TextFormatType = 'uppercase';
  @Input() htmlTagType: HtmlTagType;
  @Input() displayValue: string | number;
  @Input() defaultValue: string | number;
  @Input() currentValue: string | number;

  @ViewChild('textContent') textContent: ElementRef;

  constructor(
    private cdr: ChangeDetectorRef,
    private textTransformFormatService: TextTransformFormatService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.setFormatValueChanges(changes);
  }

  ngAfterViewInit(): void {
    this.setTextFormat();
  }

  setFormatValueChanges(changes: SimpleChanges): void {
    if (changes.displayValue && !changes.displayValue.firstChange) {
      this.setTextFormat();
    }
  }

  setTextFormat(): void {
    this.displayValue =
    this.textTransformFormatService.getTitleTransform(this.displayValue as string, this.textFormat);
    this.cdr.detectChanges();
  }

  showChangesColor(): boolean {
    if (!this.showValueChangesColor) return false;
    if (this.currentValue == null || this.defaultValue == null ) return false;
    if (typeof this.defaultValue === 'string' && typeof this.currentValue === 'string') {
      return this.defaultValue.toUpperCase() != this.currentValue.toUpperCase();  
    }
    return this.defaultValue != this.currentValue;
  }

  getChangesColor(): string {
    return this.showChangesColor() ? 'text-changed' : '';
  }

  getEmptyClass(): string {
    return this.displayValue == null || this.displayValue === '' ? 'empty' : '';
  }

}
